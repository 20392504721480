import { Injectable, Optional } from '@angular/core';
import { AngularResponse, BaseAngularService, Configuration } from './shared/angular-service';
import { RequestDefinition } from './shared/types';
import { HttpClient } from '@angular/common/http';
import { OperatorFunction, map } from 'rxjs';
import { timeout } from 'rxjs/operators';
import {
  AvatarRender,
  AvatarRenderList,
  AvatarRenderRequest,
  AvatarRenderSearch,
  DocumentRenderResponse,
  GenerateWorkflowList,
  LocalizationIntakeRequest,
  LocalizationIntakeResponse,
  LocalizationIntakeWorkflowList,
  LocalizationRenderRequest,
  LocalizationWorkflowList,
  Transcription,
  TranscriptionRequest,
  TranslateTextRequest,
  TranslateTextResponse,
  VideoRender,
  VideoRenderList,
  VideoRenderRequest,
  VideoRenderSearch,
  VoiceRender,
  VoiceRenderRequest,
  WorkflowRequest,
  WorkflowResponse,
  WorkflowSearch,
  render__57305
} from './schema';

/**
 * @see file://./../../../../modules/generate/src/rest/avatar.ts#11
 */
@Injectable({ providedIn: 'root' })
export class AvatarGenerateService extends BaseAngularService {

  routePath = '/single-clip';
  #getAllRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'query',
        name: 'search',
        sourceText: 'search',
        complex: true
      }
    ]
  };

  #getByIdRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/:id',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #renderAvatarRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/avatar.ts#28
   */
  getAll(search: AvatarRenderSearch): AngularResponse<AvatarRenderList> {
    return this.makeRequest<AvatarRenderList>([search], this.#getAllRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/avatar.ts#23
   */
  getById(id: string): AngularResponse<AvatarRender> {
    return this.makeRequest<AvatarRender>([id], this.#getByIdRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/avatar.ts#18
   */
  renderAvatar(req: AvatarRenderRequest): AngularResponse<AvatarRender> {
    return this.makeRequest<AvatarRender>([req], this.#renderAvatarRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/document-video.ts#12
 */
@Injectable({ providedIn: 'root' })
export class DocumentVideoService extends BaseAngularService {

  routePath = '/document-video';
  #completeRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/:id/complete',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      },
      {
        location: 'body',
        name: 'response',
        sourceText: 'response',
        complex: true
      }
    ]
  };

  #renderRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/document-video.ts#26
   */
  complete(id: string, response: DocumentRenderResponse): AngularResponse<void> {
    return this.makeRequest<void>([id, response], this.#completeRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/document-video.ts#20
   */
  render(req: VideoRenderRequest): AngularResponse<WorkflowRequest> {
    return this.makeRequest<WorkflowRequest>([req], this.#renderRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/health.ts#6
 */
@Injectable({ providedIn: 'root' })
export class HealthGenerateService extends BaseAngularService {

  routePath = '/health';

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

}

/**
 * @see file://./../../../../modules/generate/src/rest/localization.ts#11
 */
@Injectable({ providedIn: 'root' })
export class LocalizationGenerateService extends BaseAngularService {

  routePath = '/localization';
  #completeRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/:id/complete',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      },
      {
        location: 'body',
        name: 'response',
        sourceText: 'response',
        complex: true
      }
    ]
  };

  #getAllRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'query',
        name: 'search',
        sourceText: 'search',
        complex: true
      }
    ]
  };

  #getAllIntakeRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/intake',
    paramConfigs: [
      {
        location: 'query',
        name: 'search',
        sourceText: 'search',
        complex: true
      }
    ]
  };

  #intakeFlowCompleteRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/intake/:id/complete',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      },
      {
        location: 'body',
        name: 'response',
        sourceText: 'response',
        complex: true
      }
    ]
  };

  #intakeFlowStartRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/intake',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  #intakeRetryRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/intake/:id/retry',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #renderRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  #retryRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/:id/retry',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#37
   */
  complete(id: string, response: LocalizationIntakeResponse): AngularResponse<void> {
    return this.makeRequest<void>([id, response], this.#completeRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#17
   */
  getAll(search?: WorkflowSearch): AngularResponse<LocalizationWorkflowList> {
    return this.makeRequest<LocalizationWorkflowList>([search], this.#getAllRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#44
   */
  getAllIntake(search?: WorkflowSearch): AngularResponse<LocalizationIntakeWorkflowList> {
    return this.makeRequest<LocalizationIntakeWorkflowList>([search], this.#getAllIntakeRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#64
   */
  intakeFlowComplete(id: string, response: LocalizationIntakeResponse): AngularResponse<void> {
    return this.makeRequest<void>([id, response], this.#intakeFlowCompleteRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#51
   */
  intakeFlowStart(req: LocalizationIntakeRequest): AngularResponse<WorkflowRequest> {
    return this.makeRequest<WorkflowRequest>([req], this.#intakeFlowStartRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#58
   */
  intakeRetry(id: string): AngularResponse<void> {
    return this.makeRequest<void>([id], this.#intakeRetryRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#24
   */
  render(req: LocalizationRenderRequest): AngularResponse<render__57305> {
    return this.makeRequest<render__57305>([req], this.#renderRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/localization.ts#31
   */
  retry(id: string): AngularResponse<void> {
    return this.makeRequest<void>([id], this.#retryRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/text.ts#10
 */
@Injectable({ providedIn: 'root' })
export class TextGenerateService extends BaseAngularService {

  routePath = '/text';
  #translateTextRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/translate',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/text.ts#17
   */
  translateText(req: TranslateTextRequest): AngularResponse<TranslateTextResponse> {
    return this.makeRequest<TranslateTextResponse>([req], this.#translateTextRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/transcription.ts#10
 */
@Injectable({ providedIn: 'root' })
export class TranscriptionService extends BaseAngularService {

  routePath = '/transcription';
  #checkRenderRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/:id',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #renderRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/transcription.ts#23
   */
  checkRender(id: string): AngularResponse<Transcription> {
    return this.makeRequest<Transcription>([id], this.#checkRenderRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/transcription.ts#17
   */
  render(req: TranscriptionRequest): AngularResponse<Transcription> {
    return this.makeRequest<Transcription>([req], this.#renderRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/video.ts#12
 */
@Injectable({ providedIn: 'root' })
export class VideoGenerateService extends BaseAngularService {

  routePath = '/video';
  #cancelRequest: RequestDefinition = {
    method: 'delete',
    endpointPath: '/:id',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #getAllRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'query',
        name: 'search',
        sourceText: 'search',
        complex: true
      }
    ]
  };

  #getByIdRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/:id',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #renderRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/video.ts#49
   */
  cancel(id: string): AngularResponse<void> {
    return this.makeRequest<void>([id], this.#cancelRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/video.ts#30
   */
  getAll(search: VideoRenderSearch): AngularResponse<VideoRenderList> {
    return this.makeRequest<VideoRenderList>([search], this.#getAllRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/video.ts#25
   */
  getById(id: string): AngularResponse<VideoRender> {
    return this.makeRequest<VideoRender>([id], this.#getByIdRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/video.ts#19
   */
  render(req: VideoRenderRequest): AngularResponse<VideoRender> {
    return this.makeRequest<VideoRender>([req], this.#renderRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/voice.ts#12
 */
@Injectable({ providedIn: 'root' })
export class VoiceGenerateService extends BaseAngularService {

  routePath = '/voice';
  #checkRenderRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/:id',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  #renderRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'body',
        name: 'req',
        sourceText: 'req',
        complex: true
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/voice.ts#27
   */
  checkRender(id: string): AngularResponse<VoiceRender> {
    return this.makeRequest<VoiceRender>([id], this.#checkRenderRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/voice.ts#22
   */
  render(req: VoiceRenderRequest): AngularResponse<VoiceRender> {
    return this.makeRequest<VoiceRender>([req], this.#renderRequest);
  }
}

/**
 * @see file://./../../../../modules/generate/src/rest/workflow.ts#13
 */
@Injectable({ providedIn: 'root' })
export class WorkflowGenerateService extends BaseAngularService {

  routePath = '/workflow';
  #completeRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/:id/complete',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      },
      {
        location: 'body',
        name: 'response',
        sourceText: 'response',
        complex: true
      }
    ]
  };

  #getAllRequest: RequestDefinition = {
    method: 'get',
    endpointPath: '/',
    paramConfigs: [
      {
        location: 'query',
        name: 'search',
        sourceText: 'search',
        complex: true
      }
    ]
  };

  #renderAvatarCompleteRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/avatar/:id/complete',
    paramConfigs: [
      {
        location: 'query',
        name: 'renderId',
        sourceText: 'renderId'
      },
      {
        location: 'body',
        name: 'response',
        sourceText: 'response',
        complex: true
      }
    ]
  };

  #retryRequest: RequestDefinition = {
    method: 'post',
    endpointPath: '/:id/retry',
    paramConfigs: [
      {
        location: 'path',
        name: 'id',
        sourceText: 'id'
      }
    ]
  };

  constructor(public client: HttpClient, @Optional() options: Configuration) {
    super(options);
  }

  transform = <T>(): OperatorFunction<unknown, T> => map(o => this.consumeJSON<T>(o));
  timer = <T>(delay: number): OperatorFunction<T, T> => timeout(delay);

  /**
   * @see file://./../../../../modules/generate/src/rest/workflow.ts#32
   */
  complete(id: string, response: WorkflowResponse): AngularResponse<void> {
    return this.makeRequest<void>([id, response], this.#completeRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/workflow.ts#20
   */
  getAll(search?: WorkflowSearch): AngularResponse<GenerateWorkflowList> {
    return this.makeRequest<GenerateWorkflowList>([search], this.#getAllRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/workflow.ts#38
   */
  renderAvatarComplete(renderId: string, response: WorkflowResponse): AngularResponse<void> {
    return this.makeRequest<void>([renderId, response], this.#renderAvatarCompleteRequest);
  }

  /**
   * @see file://./../../../../modules/generate/src/rest/workflow.ts#26
   */
  retry(id: string): AngularResponse<void> {
    return this.makeRequest<void>([id], this.#retryRequest);
  }
}